import type { UrlParamCookie } from '@lib/browsers/cookies/extractUtmParamsFromCookie'
import extractUtmParamsFromCookie from '@lib/browsers/cookies/extractUtmParamsFromCookie'
import type { StringDictionary } from '@lib/types/dictionary'
import type { FormData } from '@lib/types/FormData'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import PatientRoutes from '../PatientRoutes'
import urlWithQueryParams from './urlWithQueryParams'

interface GetTrialApplicationUrlProps {
  cookies: UrlParamCookie
  patientTrialApplicationPrefilledParams: FormData
  trial: Pick<TrialAssociation, 'nctId' | 'overallStatus' | 'slug'>
}

export default function getTrialApplicationUrl({
  cookies,
  patientTrialApplicationPrefilledParams,
  trial,
}: GetTrialApplicationUrlProps) {
  if (!trial.slug) return ''

  const params = {
    nct_id: trial.nctId,
    overall_status: trial.overallStatus,
    ...extractUtmParamsFromCookie(cookies),
    url_params: new URLSearchParams(
      extractUtmParamsFromCookie(cookies),
    ).toString(),
    ...patientTrialApplicationPrefilledParams,
  }

  return urlWithQueryParams(
    PatientRoutes.trialApplyStart(trial.slug),
    params as StringDictionary,
  ).toString()
}
