/**
 * Currently we consider a trial to be monetized if it has the `monetization` metadata object.
 * In the future, we might make more distinctions between different types of monetization.
 */
export const isTrialSiteMonetized = (trialLocation?: {
  monetization?: unknown
}) => {
  return Boolean(trialLocation?.monetization)
}

export const trialHasMonetizedSites = (
  trialLocations: { monetization?: unknown }[],
) => {
  return trialLocations.some(isTrialSiteMonetized)
}
