export const ALTO_MDD_PHASE_2 = {
  nctId: 'NCT05922878',
  slug: 'phase-2-depressive-disorder-5-2023-32486',
}

export const RECOGNIFY_SCHIZ_PHASE_2 = {
  nctId: 'NCT05686239',
  slug: 'phase-2-cognitive-dysfunction-11-2022-8a042',
}

export const ENGRAIL_GAD_PHASE_2 = {
  nctId: 'NCT05749055',
  slug: 'phase-2-anxiety-disorders-3-2023-b1fb4',
}

export const CYBIN_GAD_PHASE_2 = {
  nctId: 'NCT06051721',
  slug: 'phase-2-anxiety-disorders-2024-c6eb4',
}

export const MINDMED_GAD_PHASE_3 = {
  nctId: 'MMM120300',
  slug: 'phase-3-anxiety-disorders-7-2022-8b785',
}
export const MINDMED_GAD_PHASE_3_SISTER_TRIAL = {
  nctId: 'MMM120301',
  slug: 'phase-3-anxiety-disorders-7-2022-7649f',
}

export const OTSUKA_MDD_PHASE_2_AND_3 = {
  nctId: 'NCT05593029',
  slug: 'phase-2-and-3-depressive-disorder-10-2022-de6de',
}

export const OTSUKA_GAD_PHASE_2_AND_3 = {
  nctId: 'NCT05729373',
  slug: 'phase-2-and-3-disease-1-2023-1d367',
}

export const SANOFI_MS_PHASE_3_1 = {
  nctId: 'NCT06141486',
  slug: 'phase-3-multiple-sclerosis-11-2023-45da9',
}

export const SANOFI_MS_PHASE_3_2 = {
  nctId: 'NCT06141473',
  slug: 'phase-3-multiple-sclerosis-10-2023-32eba',
}

export const SANOFI_AQUA_PHASE_3 = {
  nctId: 'NCT06241118',
  slug: 'phase-3-dermatitis-atopic-2024-6ade1',
}

export const SANOFI_COAST_2_PHASE_3 = {
  nctId: 'NCT06181435',
  slug: 'phase-3-dermatitis-atopic-11-2023-9bead',
}

export const VISTAGEN_PALISADE_3 = {
  nctId: 'NCT06358651',
  slug: 'phase-3-phobia-social-2-2024-b5ba3',
}

export const VISTAGEN_PALISADE_4 = {
  nctId: 'NCT06615557',
  slug: 'phase-3-anxiety-disorders-8-2024-652da',
}

export const NEUROCRINE_MDD_PHASE_3 = {
  nctId: 'NCT06786624',
  slug: 'phase-3-depressive-disorder-major-2025-0f611',
}

export const ARRIVO_BIO_MDD_PHASE_2 = {
  nctId: 'NCT06254612',
  slug: 'phase-2-depressive-disorder-2-2024-4f2fc',
}

// TODO: Remove after test is done as per POW-12783
export const ALZHEIMER_TEST_TRIAL = {
  nctId: 'NCT06268886',
  slug: 'phase-2-early-onset-alzheimers-disease-ad-2-2024-133c5',
}
