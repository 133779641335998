import { Icon } from '@components/primitives/Icon'
import { CloudflareImage } from '@components/primitives/Image/Cloudflare'
import { classMerge } from '@components/utilities/classMerge'
import { faHospital } from '@fortawesome/pro-regular-svg-icons/faHospital'
import type { TrialLocationNarrow } from '@modules/trials/types/TrialAssociatedWithLocations'
import { GENERIC_HOSPITAL_PHOTO_IDS } from './consts'
import { getPrioritizedImages } from './getPrioritizedImages'

export function TrialSiteImageForSiteLocation({
  altText,
  className,
  containerClass,
  fallbackToGenericImage = true,
  height = 400,
  imageIndex,
  isAboveFold = false,
  trialDigitOffset,
  trialLocation,
  width = 400,
}: {
  altText: string
  className?: string
  containerClass?: string
  fallbackToGenericImage?: boolean
  height?: number
  imageIndex?: number
  isAboveFold?: boolean
  trialDigitOffset: number
  trialLocation?: Pick<TrialLocationNarrow, 'images'>
  width?: number
}) {
  const offset = imageIndex ?? trialDigitOffset
  // sort so we should a random site, then a random city, then a random state
  const [mostRelevantImage] = getPrioritizedImages(
    trialLocation?.images ?? [],
    offset,
  )

  const imageToRender = mostRelevantImage?.externalId

  if (!imageToRender && !fallbackToGenericImage) {
    return (
      <div
        className={classMerge(
          containerClass,
          'flex items-center justify-center rounded-md bg-neutral100',
        )}
      >
        <Icon className='text-neutral600' icon={faHospital} size='2x' />
      </div>
    )
  }

  return (
    <div className={containerClass}>
      <CloudflareImage
        alt={altText}
        className={className}
        height={height}
        imageId={
          imageToRender ??
          GENERIC_HOSPITAL_PHOTO_IDS[
            offset % GENERIC_HOSPITAL_PHOTO_IDS.length
          ]!
        }
        priority={isAboveFold}
        width={width}
      />
    </div>
  )
}
