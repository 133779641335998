import { isNotPlacebo } from '@modules/interventions/helpers/isPlacebo'
import {
  getPrimaryInterventionFromTrial,
  isExperimentalArmGroup,
} from '@modules/interventions/helpers/parseTreatmentsFromTrial'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import type { ClinicalTrialTreatment } from '@prisma/client'

export const FALLBACK_TREATMENT_NAME = 'Treatment'

export function getPrimaryTreatmentFromTrial(
  trial: Pick<TrialAssociation, 'interventions' | 'armGroups'>,
) {
  return (
    getPrimaryInterventionFromTrial(trial) ?? fallbackPrimaryTreatment(trial)
  )
}

/**
 * Extract the primary treatment from a trial, defaulting to "Treatment"
 *
 * @param trial Trial to determine primary treatment
 */
export default function getPrimaryTreatmentNameFromTrial(trial: {
  treatments: Pick<ClinicalTrialTreatment, 'isPrimary' | 'name'>[]
}) {
  return (
    trial.treatments.find((t) => t.isPrimary)?.name ??
    trial.treatments[0]?.name ??
    FALLBACK_TREATMENT_NAME
  )
}

export function experimentalInterventions(
  trial: Pick<TrialAssociation, 'interventions' | 'armGroups'>,
) {
  return trial.interventions.filter((intervention) =>
    intervention.armGroupsJoin.some((armGroupJoin) =>
      isExperimentalArmGroup(armGroupJoin.armGroup),
    ),
  )
}

export function fallbackPrimaryTreatment(
  trial: Pick<TrialAssociation, 'interventions' | 'armGroups'>,
) {
  const experimentalTreatment = experimentalInterventions(trial).find((ein) =>
    isNotPlacebo(ein.name),
  )
  const activeTreatment = trial.interventions.find((intervention) =>
    intervention.armGroupsJoin.some(
      (armGroupJoin) =>
        armGroupJoin.armGroup.type === 'ActiveComparator' &&
        isNotPlacebo(armGroupJoin.armGroup.label),
    ),
  )

  return experimentalTreatment ?? activeTreatment
}
