import {
  MINDMED_GAD_PHASE_3,
  MINDMED_GAD_PHASE_3_SISTER_TRIAL,
} from '../constants/trials'
import type { TrialAssociation } from '../types/TrialAssociation'
import type { TrialForSummaryCard } from './transformTrialForTrialSummaryCard'

/**
 * This function gets the preferred title for a trial. Researchers can modify the "abbreviatedTitle" that should override any inferred trial name (conjured name from gpt, or template string name)
 */
export default function getPreferredTrialTitle(
  trial: Pick<
    TrialAssociation,
    'abbreviatedTitle' | 'briefTitle' | 'conjuredTitle'
  >,
) {
  return trial.abbreviatedTitle ?? trial.conjuredTitle ?? trial.briefTitle
}

export const getPreferredTrialTitleForAlgoliaTrial = (
  trial: TrialForSummaryCard,
  conditionToDisplay: string,
) => {
  // MindMed sponsor request
  const customTitle = getCustomTrialTitle(trial.nctId)
  if (customTitle) {
    return customTitle
  }

  const primaryTreatments = trial.treatments?.filter((t) => t.isPrimary) ?? []

  const [primaryTreatment] = primaryTreatments
  const plusMore =
    primaryTreatments.length > 1 ? ` +${primaryTreatments.length - 1} More` : ''

  const treatmentString = primaryTreatment
    ? `${primaryTreatment.name}${plusMore}`
    : trial.primaryTreatment

  const templateFallback = `${treatmentString} for ${conditionToDisplay}`

  return trial.abbreviatedTitle ?? trial.conjuredTitle ?? templateFallback
}

const getCustomTrialTitle = (nctId: string) => {
  if (nctId === MINDMED_GAD_PHASE_3.nctId) {
    return 'MM120-300 for Anxiety'
  } else if (nctId === MINDMED_GAD_PHASE_3_SISTER_TRIAL.nctId) {
    return 'MM120-301 for Anxiety'
  }
  return null
}
